import './App.css';

const Person = (name, scores) => {
    const golds = scores.filter(score => score >= 80)
    const silver = scores.filter(score => score < 80 && score >= 50)
    const bronze = scores.filter(score => score < 50)
    return {
        name: name,
        score: scores,
        medals: {
            golds: golds.length,
            silver: silver.length,
            bronze: bronze.length,
        }
    }
}

const squads = [
    Person("Aksel Dybdal", [100, 100, 86, 100, 100, 100, 100, 100, 100]),
    Person("Anders Oredsson", []),
    Person("Andreas I", []),
    Person("Andreas F", []),
    Person("Anna Munthe-Kaas", []),
    Person("August Flatby", [100, 100, 100]),
    Person("Arild Andersen", [50, 100, 100]),
    Person("Christoffer Anderson", [75, 100, 100, 100, 100, 100, 100]),
    Person("David Buverud", [75, 100, 100, 75, 100, 100]),
    Person("Diego Coy", [75, 100, 57, 75, 88, 100]),
    Person("Eirin Larsen", [86, 100, 100]),
    Person("Erik Andersen", []),
    Person("Esben Poulsen", [75, 86, 100, 100, 100, 100, 80, 100, 100]),
    Person("Fanny Ehrmann", [50, 100]),
    Person("Finn Magnus Holden", []),
    Person("Frikk Solberg", [71, 100, 100, 100]),
    Person("Jan Erik Haavet", [50, 100, 71, 100, 100, 100, 100, 100, 100]),
    Person("Jesper Vik", [75, 100, 100, 100, 100, 100]),
    Person("Joakim Lindquister", [57, 75, 50, 100]),
    Person("Johan Hedenbro", [100, 86, 75, 88, 100]),
    Person("Johan Biermann", [100]),
    Person("Kaia Wulff", [75, 100, 71, 100, 100, 100]),
    Person("Kris Riise", [86, 100, 88, 100, 75, 100]),
    Person("Krister Koen", [100, 100, 100, 100, 75, 100, 100, 50, 67]),
    Person("Kristoffer Due-Sørensen", [75, 86, 100, 88, 100, 100]),
    Person("Laura Schum", [75, 100, 57, 100, 100, 100]),
    Person("Marcus Hjelleset", [100, 71, 100, 100]),
    Person("Martin Skou", [71, 75, 88, 100]),
    Person("Meri Sørgaard", []),
    Person("Michael Wehus", [100, 100, 86, 100, 88, 100, 100, 100, 100]),
    Person("Pål Berget", [100, 50, 71, 100, 100, 83, 100, 100, 100]),
    Person("Raymond", []),
    Person("Richard Senger", [100, 100, 86, 50, 100, 100, 100, 100, 100]),
    Person("Siren Johansen", [100, 100, 86, 100, 100, 100, 100, 100, 100]),
    Person("Steinar Johansen", [75, 100, 100, 100, 100, 100]),
    Person("Terje Jenssen", [75, 100, 100, 100, 100, 100, 100]),
    Person("Veronica Berg", [75]),
    Person("Øyvind Holmstad", [100, 100, 57, 50, 100, 100, 60, 100, 100]),
]

function App() {
    const personsSorted = squads.sort((a, b) => {
        console.log(a)
        if (a.score.length === b.score.length) {
            if (a.medals.golds === b.medals.golds) {
                if (a.medals.silver === b.medals.silver) {
                    return b.medals.bronze - a.medals.bronze
                } else {
                    return b.medals.silver - a.medals.silver
                }
            } else {
                return b.medals.golds - a.medals.golds
            }
        } else {
            return b.score.length - a.score.length
        }
    })
    const squadRows = personsSorted.map((person, index) =>
        <Row key={index} index={index + 1} person={person}/>)
    return (
        <div className="App">
            <div style={{
                backgroundColor: "#112A09",
                display: "flex",
                justifyContent: "center",
                paddingLeft: 30,
                paddingRight: 30,
            }}>
                <div style={{
                    flexDirection: "column",
                    maxWidth: 600,
                    marginBottom: 100,
                    display: "flex"
                }}>
                    <img src={"./image.png"} alt="Squad Competition" style={{height: "auto", width: "500"}}/>
                    <p style={{
                        color: "#AE56F3", fontWeight: "bold", marginTop: 0, fontSize: 20, marginBottom: 40
                    }}>
                        Unloc Academy Olympics
                    </p>
                    <div style={{}}>
                        {squadRows}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Row({index, person}) {
    return (
        <div className={"hvr-grow"} style={{
            marginTop: 18,
            textAlign: "left",
            color: "#C4EE4D",
            fontWeight: "bold",
            fontSize: 20,
            display: "flex",
            flexDirection: "row"
        }}>
            <span style={{marginRight: 12}}>{index}.</span>
            <span style={{marginRight: 36}}>{person.name}</span>
            <div style={{marginLeft: 'auto'}}>
                <span style={{marginLeft: "auto", paddingRight: 12}}>{person.medals.golds} 🏅</span>
                <span style={{marginLeft: "auto", paddingRight: 12}}>{person.medals.silver} 🥈</span>
                <span style={{marginLeft: "auto"}}>{person.medals.bronze} 🥉</span>
            </div>
        </div>
    )
}

export default App;
